<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle del proceso: N° {{ bajaAutoId }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="itemsDetalleBajasAuto"
        class="elevation-1"
        :loading="isLoading"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        no-data-text="No hay datos para este detalle"
        item-key="procesoBajaDetalleId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Estado:</strong>
            {{ item.estado }}
          </td>
        </template>
        <template v-slot:[`item.numeroAfiliado`]="{ item }">
          <span
            :style="
              item.estado ==
                'Afiliados no Procesados - Existen Movimientos posteriores a la fecha' ||
              item.estado == 'Error: Existen movimientos posteriores a la fecha'
                ? 'color:red'
                : ''
            "
          >
            {{ item.numeroAfiliado }}</span
          >
        </template>
        <template v-slot:[`item.nombreAfiliado`]="{ item }">
          <span
            :style="
              item.estado ==
                'Afiliados no Procesados - Existen Movimientos posteriores a la fecha' ||
              item.estado == 'Error: Existen movimientos posteriores a la fecha'
                ? 'color:red'
                : ''
            "
          >
            {{ item.nombreAfiliado }}</span
          >
        </template>
        <template v-slot:[`item.documento`]="{ item }">
          <span
            :style="
              item.estado ==
                'Afiliados no Procesados - Existen Movimientos posteriores a la fecha' ||
              item.estado == 'Error: Existen movimientos posteriores a la fecha'
                ? 'color:red'
                : ''
            "
          >
            {{ item.documento }}</span
          >
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right py-4"
                v-bind="attrs"
                small
                outlined
                :disabled="itemsDetalleBajasAuto.length == 0"
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "VerDetalleBajaAutomatica",
  props: {
    bajaAutoId: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    isLoading: false,
    search: "",
    searchIcon: enums.icons.SEARCH,
    expanded: [],
    itemsDetalleBajasAuto: [],
    headers: [
      {
        text: "N° de afiliado",
        align: "left",
        value: "numeroAfiliado",
        sortable: false
      },
      {
        text: "Nombre afiliado",
        align: "left",
        value: "nombreAfiliado",
        sortable: false
      },
      {
        text: "Tipo y N° de documento",
        align: "center",
        value: "documento",
        sortable: false
      },
      { text: "Estado", value: "data-table-expand", align: "end" }
    ],
    allowedActions: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setDetalleBajasAuto();
  },
  methods: {
    ...mapActions({
      getDetalleBajaAutomaticaById: "afiliaciones/getDetalleBajaAutomaticaById",
      setAlert: "user/setAlert"
    }),
    async setDetalleBajasAuto() {
      this.isLoading = true;
      const response = await this.getDetalleBajaAutomaticaById(this.bajaAutoId);
      this.itemsDetalleBajasAuto = response;
      this.isLoading = false;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsDetalleBajasAuto.forEach(x =>
        result.push({
          ["N° afiliado"]: x.numeroAfiliado,
          ["Nombre afiliado"]: x.nombreAfiliado,
          ["Tipo y N° de documento"]: x.documento,
          ["Estado"]: x.estado
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle del proceso de bajas automáticas"
      );
    },
    closeModalVerDetalle() {
      this.$emit("closeModalVerDetalle");
    }
  }
};
</script>

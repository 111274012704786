<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row>
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" v-if="canGoConfig" class="mt-3">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigBajasAutomaticas()"
                >
                  <v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de bajas automáticas</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container class="pr-4">
            <v-row justify="end">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="in-out">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- fecha desde -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-col cols="2" align="end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="procesoBajaId"
            :items="itemsBajasAuto"
            :loading="loading"
            class="elevation-1"
            :search="search"
            no-data-text="No hay datos para las fechas ingresadas"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      :disabled="item.usuarioAnula != null"
                      @click="verDetalleProceso(item.procesoBajaId)"
                    >
                      {{ seeIcon }}
                    </v-icon>
                  </section>
                </template>
                <span v-if="item.usuarioAnula != null"
                  >El proceso ya sido anulado</span
                >
                <span v-else>Ver detalle del proceso</span>
              </v-tooltip>
              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      :disabled="item.usuarioAnula != null"
                      @click="anularProcesoBajaAuto(item.procesoBajaId)"
                    >
                      {{ anuladoIcon }}
                    </v-icon>
                  </section>
                </template>
                <span v-if="item.usuarioAnula != null"
                  >El proceso ya sido anulado</span
                >
                <span v-else>Anular proceso</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
        v-if="showModalExportarExcel"
        v-model="showModalExportarExcel"
        max-width="40%"
        persistent
    >
          <v-card>
            <v-container>
              <v-card-title>
                <span class="pl-1 primary--text"
                  >Se generará un archivo Excel con el detalle</span
                >
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="to-right"
                  text
                  @click="exportExcel()"
                >
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
    <v-dialog
      v-if="openModalVerDetalle"
      v-model="openModalVerDetalle"
      max-width="80%"
      @keydown.esc="closeModalVerDetalle"
      persistent
    >
      <VerDetalleBajaAutomatica
        :bajaAutoId="bajaAutoId"
        @closeModalVerDetalle="closeModalVerDetalle"
      ></VerDetalleBajaAutomatica
    ></v-dialog>
    <DeleteDialog
      :titleProp="titleAnular"
      :textDelete="textDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      @onDeleteItem="confirmAnular()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import VerDetalleBajaAutomatica from "@/components/modules/afiliaciones/afiliados/VerDetalleBajaAutomatica.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ConsultaBajasAutomaticas",
  components: {
    DeleteDialog,
    PageHeader,
    Ayuda,
    FiltersSelected,
    VerDetalleBajaAutomatica
  },
  directives: { mask },
  data: vm => ({
    allowedActions: null,
    rules: rules,
    showExpand: false,
    showHelp: false,
    title: enums.titles.CONSULTA_BAJAS_AUTOMATICAS,
    search: "",
    searchIcon: enums.icons.SEARCH,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    anuladoIcon: enums.icons.CIRCLE_NONE,
    seeIcon: enums.icons.SEE,
    calendarIcon: enums.icons.CALENDAR,
    settingsIcon: enums.icons.SETTINGS,
    showIcon: true,
    optionCode: enums.webSiteOptions.CONSULTAR_BAJAS_AUTOMATICAS,
    //filtros
    showFilters: true,
    filtersApplied: [],
    isFormValid: false,
    loading: false,
    menu1: null,
    menu2: null,
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaHasta: new Date().toISOString().substr(0, 10),
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
        .toISOString()
        .substr(0, 10)
    ),
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    itemsBajasAuto: [],
    //anular
    textDelete: "",
    resultsExportExcel: [],
    showAnularModal: false,
    showModalExportarExcel: false,
    titleAnular: "¿Desea anular el proceso?",
    canAnular: false,
    canGoConfig: false,
    bajaAutoId: null,
    idToAnular: null,
    openModalVerDetalle: false,
    headers: [
      {
        text: "Nº de proceso",
        sortable: false,
        value: "procesoBajaId"
      },
      {
        text: "Fecha inicio",
        sortable: false,
        value: "fechaInicio"
      },
      {
        text: "Fecha fin",
        sortable: false,
        value: "fechaFin"
      },
      {
        text: "Usuario anulación",
        sortable: false,
        value: "usuarioAnula"
      },
      {
        text: "Fecha anulación",
        sortable: false,
        value: "fechaAnula"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "5%"
      }
    ]
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.applyFilters();
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getBajasAutomaticas: "afiliaciones/getBajasAutomaticas",
      anularProcesoBajaAutomatica: "afiliaciones/anularProcesoBajaAutomatica",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ANULAR_PROCESO_BAJA_AUTOMATICA:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIG_BAJAS_AUTOMATICAS:
            this.canGoConfig = true;
            break;
          default:
            break;
        }
      });
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async applyFilters() {
      this.loading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const data = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta
      };
      try {
        const response = await this.getBajasAutomaticas(data);
        this.itemsBajasAuto = response;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleFiltersSelected() {
      this.showFilters = !this.showFilters;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    closeModalVerDetalle() {
      this.openModalVerDetalle = false;
    },
    verDetalleProceso(item) {
      this.bajaAutoId = item;
      this.openModalVerDetalle = true;
    },
    anularProcesoBajaAuto(id) {
      this.textDelete =
        "Se anularán todas las bajas automáticas creadas por el proceso.";
      this.idToAnular = id;
      this.showAnularModal = true;
    },
    goToConfigBajasAutomaticas() {
      this.$router.push({
        name: "ConfigBajasAutomaticas"
      });
    },
    async confirmAnular() {
      const response = await this.anularProcesoBajaAutomatica(this.idToAnular);
      if (response.status === 200) {
        if(response.data.data.length === 0){
          this.setAlert({ type: "success", message: "Anulado con éxito." });
          this.showAnularModal = false;
        }else{
          this.setAlert({ type: "warning", message: "No es posible anular ya que existen movimientos posteriores al proceso. Se generará el detalle de movimientos." });
          this.showAnularModal = false;
          this.showModalExportarExcel = true;
          this.resultsExportExcel = response.data.data;
        }
      }
      this.applyFilters();
    },
    exportExcel() {
      let result = [];
      this.resultsExportExcel.forEach(x =>
        result.push({
          ["N° de afiliado"]: x.numeroAfiliado,
          ["Tipo de documento"]: x.tipoDoc,
          ["N° de documento"]: x.docId,
          ["Apellido"]: x.benApe,
          ["Nombre"]: x.benNom,
          ["Convenio"]: x.osNom,
          ["Nombre de plan"]: x.planNom,
          ["Fecha de movimiento"]: x.fechaMov,
          ["Movimiento"]: x.movimiento,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle del proceso");
      this.setAlert({ type: "success", message: "Archivo generado con éxito." });
      this.showModalExportarExcel = false;
    },
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v("Detalle del proceso: N° "+_vm._s(_vm.bajaAutoId)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsDetalleBajasAuto,"loading":_vm.isLoading,"search":_vm.search,"show-expand":"","expanded":_vm.expanded,"no-data-text":"No hay datos para este detalle","item-key":"procesoBajaDetalleId"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Estado:")]),_vm._v(" "+_vm._s(item.estado)+" ")])]}},{key:"item.numeroAfiliado",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado ==
              'Afiliados no Procesados - Existen Movimientos posteriores a la fecha' ||
            item.estado == 'Error: Existen movimientos posteriores a la fecha'
              ? 'color:red'
              : '')},[_vm._v(" "+_vm._s(item.numeroAfiliado))])]}},{key:"item.nombreAfiliado",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:(item.estado ==
              'Afiliados no Procesados - Existen Movimientos posteriores a la fecha' ||
            item.estado == 'Error: Existen movimientos posteriores a la fecha'
              ? 'color:red'
              : '')},[_vm._v(" "+_vm._s(item.nombreAfiliado))])]}},{key:"item.documento",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:(item.estado ==
              'Afiliados no Procesados - Existen Movimientos posteriores a la fecha' ||
            item.estado == 'Error: Existen movimientos posteriores a la fecha'
              ? 'color:red'
              : '')},[_vm._v(" "+_vm._s(item.documento))])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"left":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"to-right py-4",attrs:{"color":"primary","small":"","outlined":"","disabled":_vm.itemsDetalleBajasAuto.length == 0},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])})],1),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModalVerDetalle}},[_vm._v(" Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }